<template>
  <div>
    <v-app-bar app scroll-off-screen class="sub-topbar" color="white py-1">
      <v-container class="d-flex align-center h-full">
        <div class="d-flex justify-space-between align-center flex-grow-1">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/app">
              <v-img
                class="me-2 logo"
                width="40"
                height="40"
                src="@/assets/images/icon.svg"
                alt=""
              />
            </router-link>
          </v-toolbar-title>
          <div class="">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  small
                  class="mr-3"
                  color="grey lighten-2"
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="logout"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Sair
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
// import HeaderNavbar from '@/views/home/header-navbar'
// import Navbar from '@/views/home/navbar'
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "BaseAppBar",
  directives: {
    onClickaway: onClickaway,
  },
  data: () => ({
    colorNav: false,
    dialog: false,
    isToggleNavbar: false,
    drawer: false,
    group: null,
  }),

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },

    toggleNavbar() {
      if (this.$route.path == "/") {
        this.isToggleNavbar = false;
      } else {
        this.isToggleNavbar = !this.isToggleNavbar;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.z-999 {
  z-index: 999;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
.sale-page-one {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sale-page-two {
  .sub-topbar {
    &.v-app-bar--is-scrolled {
      position: relative;
      .dropdown-ecommerce {
        display: none;
      }
    }
  }

  .navbar {
    &.v-app-bar--is-scrolled {
      top: 0;
      margin-bottom: 0px;
    }
  }
}
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: none !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  .search-bar-dropdown {
    position: absolute;
    right: 0;
    border-radius: 22px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
    box-shadow: none !important;
    @media (max-width: $md) {
      display: none;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
</style>
